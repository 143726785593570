.navbar {
    background-color: #f6eb79;
  }
  
  .Tamra {
    left: 15px;
    top: 239px;
  }
  .Tamara {
    width: 383px;
    background-color: #f3e449;
    padding: 15px;
    height: auto;
  }
  