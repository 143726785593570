@media (max-width: 768px) {
    .deluxe .col-md-10 {
      flex: 0 0 100%;
      max-width: 100%;
      margin-bottom: 15px;
    }
  }
  
  @media (max-width: 576px) {
    .deluxe .col-md-10 {
      padding: 0;
    }
    .deluxe .col-md-10 h6 {
      font-size: 16px;
    }
    .deluxe .col-md-10 p.Adults {
      font-size: 14px;
    }
    .Total .row.btn {
      text-align: center;
    }
  }

@media (max-width: 992px) { 
  .col-lg-8, .col-md-6 {
    flex: 0 0 100%;
  }
}

@media (max-width: 768px) { 
  .form-control {
    width: 100%;
    margin: 10px 0;
  }
}

@media (max-width: 576px) {
  .container {
    width: 100%;
    padding: 10px;
  }
  .text-end {
    text-align: center; 
  }
}