#root {
  overflow: hidden;
 
}
.LoginColor {
  background-color: #000;
}

.Adults{
  font-family: "Lato";
font-size: 12px;

font-weight: 400;



}
.Buttonbtn {
  margin-left: 10px;
  margin-right: 1px;
  padding-right: 13px;
  margin-bottom: 10px;
  margin-top: 10px;
  background-color: #000;
}
.stay-booking{
  padding: 20px;
 
}

.editImage{
  padding-left: 9px;
  padding-bottom: 8px;
}
.back-colors , .back-colors h2{
  background-color: #000000;
 padding: 5px 10px 5px 11px;
  color:#fff;


}
.total-rate{
  padding-top: 10px;
  padding-left: 9px;
}
.Deluxe-Room{
  padding-left: 10PX;
  padding-top: 10PX;
}
.back-colors h2{

  font-family: "Lato";
font-size: 20px;
font-weight: 500;

letter-spacing: 0.03em;
text-align: left;

}

.bookings{
  font-family: "Lato";
font-size: 16px;
font-weight: 400;


}
.stunning-hotel1{
  font-family: "Lato";
  font-size: 16px;
  font-weight: 400;
  
  padding-bottom: 5px;
}
.stunning-hotel{
  font-family: "Lato";
font-size: 16px;
font-weight: 400;

padding-top: 7px;


}
.vertical-line {
/* Line color */
border: 0.3px solid #5C5C5C;
  border: none;
  transform: rotate(90deg); /* Rotates the horizontal line */
}

.Booing-login {
  background-color: #fff;
  padding: 20px;
  margin-top: 70px;

  border-radius: 20px;
  opacity: 0px;
}
input,
button,
select,
optgroup,
textarea {

  font-family: inherit;
  font-size:15px;
  line-height: inherit;
  border: #7F7F7F;
 
}
.AddRooms1{
 padding-left: 9px;
 padding-right: 9px;
 background: #7F7F7F;
 color: #fff;
}
.AddRooms2{
  padding-left: 7px;
  padding-right: 7px;
  background: #7F7F7F;
  color: #fff;
 }
 .num{
  padding-left: 12px;
  padding-right: 12px;
 padding-bottom: 2px;
  
  
 }
 .AddRooms1, .AddRooms2 ,.num{
  border: 1px solid #7F7F7F;
 }
.Enquiry-Count{
  padding-top: 20px;
  padding-bottom: 20px;
}






.ext-center {
  padding-top: 15px;
}
label {
  padding: 0px !important ;
}

.Standard {
  padding: 10px;
  border: 1px solid #a0a0a0;
  border-radius: 4px;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 400;
}
.Select {
  font-family: "Nunito Sans";
  font-size: 20px;
  font-weight: 600;
}
.Standard-Login {
  padding: 10px;
  background-color: #000;
  color: #f0dd17;
  font-family: "Nunito Sans";
  font-size: 18px;
  font-weight: 600;
  border-radius: 6px;
  margin-bottom: 50px;
  border: 0;
}

p {
  margin-bottom: 5px !important;
  font-family: "Poppins";
  font-size: 14px;
  font-weight: 400;

  overflow: hidden !important ;
}
h3 {
  font-family: "Lato";
  font-size: 22px;
  font-weight: 500;

  text-align: left;
}
hr {
  margin: 0px !important;
}
h1,
h2 {
  font-family: "Poppins";
  font-size: 18px !important;
  font-weight: 600;
  line-height: 27px;
  overflow: hidden !important ;
  margin-bottom: 5px !important;
}

.circle-container {
  text-align: center;
  margin-bottom: 15px;
}

.circle {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #C6C6C6;
  color: #111;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
}


.circle-label {
  color: #333;
  text-align: center;
  margin-top: 10px;
  letter-spacing: 1px;
  font-size: 18px;
  font-family: "Lato"
}





.Book {
  background-color: #0000000d;
}

.booking {
  background-color: #007bff;
}




.date {
  font-family: "Lato";
  font-size: 20px;
  font-weight: 500;
margin-top: 20px;

  
}
.Stay-booking2{
  border: 1px solid #7F7F7F;
 
}


.One {
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
}
.deluxe {
  margin-top: 10px;
}


.booking-stay {
  border: 1px solid #7f7f7f;
  height: 380px;
  margin-left: 4px;
}

.circle-label {
  color: #333;
}

.nav-link {
  font-family: "Lato";
  font-size: 16px;
  font-weight: 700;
}

.filter{
  /* padding: 8px; */
  background-color: #000000;
  color: #fff;
  font-size: 14px;
  font-family: "Lato";
  font-weight: 400;
  padding: 10px;
  padding-right: 30px;
  margin-top: 20px;
  

}
.Filter-Your-Search{
  position: relative;
  left: 10px;
  font-size: 16px;
  font-family: "Lato";
  font-weight: 400;
}
.Deluxe-Box {
  padding-left: 20px;
  padding-right: 20px;
  margin-right: 20px;
  border: 0.5px solid #7f7f7f;
  margin-top: 20px;
  padding-bottom: 10px;
}
.Capacity {
  border: 0.5px solid #7f7f7f;
  padding-top: 10px;
  padding-bottom: 10px;
}
.Ex {
  position: relative;
  bottom: 2px;
  left: 10px;
}
.Rates {
  margin-top: 27px;
  font-family: "Lato";
font-size: 16px;
color: #7F7F7F
;



}
.Enquiry {
  margin-top: 25px;
}
.Rates-Exclusive{
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 22px;
  line-height: 18px;
}
.Name {
  padding-top: 50px;
  padding-bottom: 50px;
}
.AddRooms {
  background-color: #f0dd17;
   
  color: #fff;
}
.Maximum-Capacity{
  font-family: "Lato";
  font-size: 18px;
  font-weight: 400;
  color: #7F7F7F;
  
}
.Price-details {
  font-family: "Lato";
  font-size: 14px;
  font-weight: 400;
  color: #7F7F7F;
  
}
.button-small {
  padding: 5px 10px; /* Smaller padding */
  font-size: 12px; /* Smaller font size */
}



.circle.active {
  background-color: #f0dd17;
}

.circle.completed {
  background-color: #7f7f7f;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 30px;
}

.card-item {
  width: 300px;
  margin-bottom: 20px;
  padding: 10px;
}
.card-body {
  flex: 1 1 auto;
  padding: 0;
  padding-top: 10px; /* Set padding to zero */
  color: var(--bs-card-color);
}
/* ButtonStyles.css */

.handleIncrease {
  background-color: #7F7F7F;
  color: #fff; /* Adjust text color as needed */
  border: none;
  
  padding: 1px;
  width: 30px;
  cursor: pointer;
  outline: none;
}
.Zero {
  border: 0.1px solid #7f7f7f;
  position: relative;
  top: 1px;

}
.Loginpage{
  background-color: #222222
  ;
}
.opinion {
  position: relative;
  bottom: 2px;
}
.Restaurant {
 padding-left: 25px;
 padding-top: 10px;
 padding-bottom: 10px;
}
.vertical-line {
  border: 0.3px solid #5C5C5C;
  height: 50px;
  transform: rotate(180deg);
  width: 0px;
  margin: 0 auto;


}
hr{
  border: 0.3px solid #5C5C5C80
}
.hotel-room{
  font-family: "Lato";
font-size: 20px;
font-weight: 500;
padding-top: 7px;
padding-bottom: 7px;
color: #fff;
background-color: #000;

}
.Living-room{
  font-family: "Lato";
  font-size: 18px;
  font-weight: 500;
 

  
}
.Rate-Details{
  padding-top: 10px;
  padding-bottom: 5px;
}

.UserFrom {
  padding: 40px;
  background-color: #fafafa;
  border: 0.5px solid #7f7f7f;
}

.PlaceholderName {
  padding: 10px;
}
.MobileNumber {
  margin-top: 40px;
  padding: 10px;
}
.Email {
  margin-top: 40px;
  padding: 10px;
}
.SpecialRequest {
  margin-top: 40px;
  padding: 10px 10px 100px 10px;
}
.Hotel-Policy {
  padding-top: 50px;
  padding-bottom: 20px;
}
.checkbox {
  padding-bottom: 50px;
}
.Book-Now {
  padding-bottom: 40px;
}
.Book-Now button {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 5px;
  padding-bottom: 5px;
  border: 0px;
  background-color: #000;
  color: #fff;
}
.UserForm {
  border: 0.5px solid #7f7f7f;
  padding: 50px;
}
.Hotel {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #000;
  color: #fff;
}
.Heading {
  padding-top: 5px;
  padding-bottom: 5px;
}
.Userdate {
  padding-top: 20px;
  padding-bottom: 10px;
}
.UserBooking {
  margin-bottom: 10px;
}
.Details {
  padding-top: 5px;
  padding-bottom: 5px;
}
.Form-page {
  margin-top: 100px;
}

.User-Name h4 {
  font-family: "Nunito Sans";
  font-size: 35px;
  font-weight: 700;
}

.User-Name {
  padding-bottom: 50px;
  padding-top: 50px;
}
.Logo {
  padding-top: 50px;
}

.error {
  color: red;
  padding-top: 10px;
}
.card-title{
  font-family: "Lato";
font-size: 20px;
font-weight: 500;



}
.UserFrom {
  border-radius: 20px;
}
.UserFrom h1{
  font-family: "Nunito Sans";
font-size: 40px !important;
font-weight: 700;
line-height: 50px;



}
.UserFrom , label , input{
  font-family: "Nunito Sans";
font-size: 20px;
font-weight: 600;
line-height: 27.28px;


}
.loginbutton button{
  background-color: #222222;
  padding: 10px;
}
.loginbutton button {
  color:#F8E543 ;
  font-family: "Nunito Sans";
font-size: 18px;
font-weight: 800;
line-height: 24.55px;



}
.check-box a{
  color: #222222;
  font-family: "Nunito Sans";
font-size: 16px;
font-weight: 600;
line-height: 21.82px;
text-align: left;

}
.form-control{
  margin-top: 10px;
  padding: 13PX;
  border-radius: 8PX;
}
.form-group{
  margin-top: 20px;
}

.btn-dark{
  padding-right:40px;
  padding-left: 40px;
  border-radius: 4px;
}
a{
  text-decoration: none;

  font-family: "Lato";
font-size: 16px;
font-weight: 700;
padding: 8px;
border-radius: 4px;
color: #fff;
}
.GuestBooking{
  border: 1px solid #7F7F7F;

}


.back-colors1{
  background-color: #000000;
padding: 5px 10px 5px 20px;
 color:#fff;
}
.back-colors1 h2{

  font-family: "Lato";
font-size: 20px;
font-weight: 500;

letter-spacing: 0.03em;
text-align: left;

}
.success{
  background-color: #000;
  padding: 10px;
color: #f0dd17;
border-radius: 8px;
}
.Loginpage{
  height: 100vh;
}
